body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
  margin: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #1a1b1c;
  border-radius: 5px;
  border: solid 2px #282c34;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #1a1b1c;
  border-radius: 5px;
  border: solid 2px #1a1b1c;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.drawer-content {

}

.drawer-container {
  --transition-speed: 0.3s;
}

.drawer {
  background: #fff;
  height: 100%;
  width: 26vh;
  overflow: auto;
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
}
.drawer::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.drawer.left {
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

.drawer.right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawer.top {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  height: 40%;
}

.drawer.bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  height: 40%;
}

.drawer-container.in.open .left,
.drawer-container.in.open .right {
  transform: translateX(0);
}

.drawer-container.in.open .top,
.drawer-container.in.open .bottom {
  transform: translateY(0);
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.drawer-container.in.open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.dropbox {
  text-align: center;
  padding: 20px;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

/* #region ---------------------[ DropZone ]---------------------------------------------------------------------*/
.container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
}
.container img {
  height: 200px;
  width: 200px;
  margin-right: 15px;
}
.btn {
  padding: 15px;
  background-color: #de1a1a;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.btn:hover{
  background-color: #945c5c;
}